<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>通知管理</h5>
				<div class="formgroup-inline">
					<Button label="发布" icon="pi pi-plus" class="mr-6" @click="onAddNotify" />
					<!-- <div class="field">
						<label for="adminCode" class="p-sr-only">关键字查询</label>
						<InputText id="adminCode" type="text" v-model="searchContent" placeholder="关键字查询" />
					</div>
					<Button label="查询" @click="getNotifyList"></Button> -->
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="notifyList" :paginator="true" class="p-datatable-gridlines" :rows="page_size" dataKey="id"
					:loading="loading1" responsiveLayout="scroll" @page="onPage" :lazy="true"
					:totalRecords="totalCount">
					<template #empty>
						No notifies found.
					</template>
					<template #loading>
						Loading notifies data. Please wait.
					</template>

					<Column field="name" header="ID" style="min-width:1rem">
						<template #body="{ data }">
							{{ data.id }}
						</template>
					</Column>

					<Column field="name" header="内容" style="min-width:20rem">
						<template #body="{ data }">
							{{ data.text }}
						</template>
					</Column>

					<Column field="name" header="类型" style="min-width:3rem">
						<template #body="{ data }">
							<Tag severity="info" v-if="data.type == 1" value="系统"></Tag>
							<Tag severity="success" v-else value="企业"></Tag>
						</template>
					</Column>

					<Column field="name" header="发布时间" style="min-width:3rem">
						<template #body="{ data }">
							{{ formatTime(data.times) }}
						</template>
					</Column>

					<Column header="操作" style="min-width:2rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button label="删除" class="p-button-danger p-button-text mr-1"
									@click="delNotify(data, $event)" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog header="发布通知" v-model:visible="displayAddNotify" :style="{ width: '30vw' }" :modal="true">
		<div class="col-12 p-fluid">
			<div class="field p-fluid">
				<label>规则</label>
				<div class="grid">
					<span class="p-float-label mr-3 mt-2 ml-2">
						<Dropdown id="notifyName" :options="notifyType" v-model="notifyTypeSelected" dataKey="id"
							:showClear="true" optionLabel="name" optionValue="id" placeholder="选择类型">
						</Dropdown>
					</span>

					<span class="p-float-label mr-3 mt-2 ml-2">
						<TreeSelect v-model="enterpriseSelected" :options="enterpriseList" display="chip"
							placeholder="选择企业权限" selectionMode="checkbox"></TreeSelect>
					</span>
				</div>
			</div>

			<div class="field p-fluid mt-4">
				<label for="saferName">内容（ 限140个字符 ）</label>
				<span class="p-float-label mt-2">
					<Textarea v-model="notifyContent" rows="5" cols="30" />
				</span>

			</div>
		</div>
		<template #footer>
			<Button label="取消" icon="pi pi-times" @click="displayAddNotify = false" class="p-button-text" />
			<Button label="确定" icon="pi pi-check" @click="doAddNotify" autofocus />
		</template>
	</Dialog>


</template>

<script>
import dateUtil from "../utils/dateUtil";

export default {
	data() {
		return {
			displayAddNotify: false,
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 10, // 每页条数
			notifyList: null,
			notifyTypeSelected: null,
			notifyType: [
				{ id: 1, name: "系统" },
				{ id: 0, name: "企业" }
			],
			enterpriseList: [], // 企业
			enterpriseSelected: null,
			notifyContent: '',
			searchContent: '',
		}
	},
	watch: {
		// roleTypeSelected: function (newVal, oldVal) {
		// 	console.log('roleTypeSelected:' + oldVal)
		// 	console.log('roleTypeSelected:' + newVal)
		// },
		// saferEnterId: function (newVal, oldVal){
		// 	console.log('saferEnterId old')
		// 	console.log(oldVal)
		// 	console.log('saferEnterId new')
		// 	console.log(newVal)

		// 	saferEnterId = oldVal;
		// }
		notifyContent: function (newVal, oldVal) {
			let text = newVal.slice(0, 140);

			this.$nextTick(function () {
				this.notifyContent = text;
			})

			console.log(oldVal)
		}
	},
	created() {
	},
	mounted() {
		this.getNotifyList();
		//this.getRoleList();
		this.getEnterTree();
	},
	methods: {
		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 2000 });
		},
		goodMessage() {
			this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
		},

		onRoleNameChange(e) {
			console.log(e)
		},

		onSaferEnterIdChange(e) {
			console.log(e)
		},

		// 获取全部企业
		getEnterTree() {
			this.$http("/api/admin/enterprise/tree").then((res) => {
				if (res.code == 200) {
					let _enterpriseList = res.data.map((e) => {
						var n = new Object();
						n.key = e.value;
						n.label = e.text;
						n.data = e.value;
						n.icon = 'pi pi-fw pi-building';
						n.selectable = true;
						n.children = e.children.map(ee => {
							var nn = new Object();
							nn.key = ee.value;
							nn.label = ee.text;
							nn.data = ee.value;
							nn.icon = 'pi pi-fw pi-map-marker';
							nn.selectable = true;
							return nn;
						});

						return n;
					});

					this.enterpriseList.length = 0;
					for(let i=0; i<_enterpriseList.length; i++){
						if(_enterpriseList[i].children.length > 0){
							this.enterpriseList.push(_enterpriseList[i])
						}
					}
				}
			})
		},

		onPage(event) {
			//this.cars = //load the data between (event.first) and (event.first + event.rows) from a remote datasource
			//console.log(event);
			this.page_index = event.page + 1;
			this.getNotifyList();
		},

		getNotifyList() {
			this.$http('/api/admin/message/list', {
				enterprise_id: '',
				partition_id: '',
				times: '',
				page: this.page_index,
				page_size: this.page_size
			}).then(res => {
				this.totalCount = res.data.total;
				this.notifyList = res.data.data
			})
		},

		getPermission(id) {
			return this.$http('/api/admin/user/permission', {
				user_id: id,
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					console.log(res.data)
					return res.data;
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 3000  });
					return null;
				}
			})
		},

		onAddNotify() {
			this.notifyTypeSelected = null;
			this.enterpriseSelected = null;
			this.notifyContent = '';
			this.displayAddNotify = true;
		},

		doAddNotify() {

			console.log(this.notifyTypeSelected)
			console.log(this.enterpriseSelected)

			if (this.notifyTypeSelected === null ||
				this.notifyTypeSelected === '') {
				this.$toast.add({ severity: 'error', summary: '发生错误', detail: '请选择通知类型', life: 3000 });
				return;
			}

			if (this.enterpriseSelected === null
				|| this.enterpriseSelected === '') {
				this.$toast.add({ severity: 'error', summary: '发生错误', detail: '请选择企业', life: 3000 });
				return;
			}

			this.$http("/api/admin/message/create", {
				type: this.notifyTypeSelected,
				tree_ids: this.enterpriseSelected,
				text: this.notifyContent,
			}).then((res) => {
				if (res.code == 200) {
					this.goodMessage();
					this.displayAddNotify = false;
					this.getNotifyList();
				} else {
					this.errorMessage(res.msg)
				}
			});
		},

		delNotify(item, event) {
			this.$confirm.require({
				message: '确定要删除这条通知吗',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http("/api/admin/message/del", {
						id: item.id
					}).then((res) => {
						if (res.code == 200) {
							this.goodMessage();
							this.getNotifyList();
						} else {
							this.errorMessage(res.msg);
						}
					});
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},

		formatTime(s) {
			return dateUtil.formatDateFromMs(s);
		}
	}
}
</script>

<style lang="scss" scoped>
.p-treeselect {
	width: 20rem;
}
</style>